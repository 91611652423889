"use client";
import { Button } from "@/components/ui/button";
import { Form, FormMessage } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";

import { mapToHookFormErrors } from "@next-safe-action/adapter-react-hook-form";
import { Icons, cn } from "@watt/common";
import type {
  BindArgsValidationErrors,
  ValidationErrors
} from "next-safe-action";
import type { Infer, Schema } from "next-safe-action/adapters/types";
import type {
  HookBaseUtils,
  HookCallbacks,
  HookSafeActionFn,
  UseActionHookReturn
} from "next-safe-action/hooks";
import { useMemo } from "react";
import { type DefaultValues, type FormState, useForm } from "react-hook-form";
import AutoFormObject from "./fields/object";
import type { Dependency, FieldConfig } from "./types";
import { getDefaultValues, getObjectFormSchema } from "./utils";

export function AutoFormButton({
  children,
  className,
  disabled,
  isSubmitting,
  ...props
}: React.ComponentProps<typeof Button> & {
  isSubmitting?: boolean;
}) {
  return (
    <Button
      type="submit"
      disabled={disabled || isSubmitting}
      className={className}
      {...props}
    >
      {isSubmitting ? (
        <Icons.spinner className="h-4 w-4 animate-spin" />
      ) : (
        (children ?? "Submit")
      )}
    </Button>
  );
}

// TFieldValues extends FieldValues Maybe?
function AutoForm<SchemaType extends Schema>({
  formSchema,
  values: valuesProp,
  onValuesChange: onValuesChangeProp,
  onParsedValuesChange,
  formAction: formActionProp,
  fieldConfig,
  children,
  className,
  dependencies
}: {
  formSchema: SchemaType;
  values?: Infer<SchemaType>;
  onValuesChange?: (values: Partial<Infer<SchemaType>>) => void;
  onParsedValuesChange?: (values: Partial<Infer<SchemaType>>) => void;
  formAction:
    | ReturnType<
        <
          ServerError extends never,
          S extends SchemaType | undefined,
          const BAS extends readonly SchemaType[],
          CVE extends ValidationErrors<SchemaType>,
          CBAVE extends BindArgsValidationErrors<BAS>,
          Data
        >(
          safeActionFn: HookSafeActionFn<ServerError, S, BAS, CVE, CBAVE, Data>,
          utils?: HookBaseUtils<S> &
            HookCallbacks<ServerError, S, BAS, CVE, CBAVE, Data>
        ) => UseActionHookReturn<ServerError, S, BAS, CVE, CBAVE, Data>
      >
    // biome-ignore lint/suspicious/noExplicitAny: <fix later>
    | any;

  fieldConfig?: FieldConfig<Infer<SchemaType>>;
  children?:
    | React.ReactNode
    | ((formState: FormState<Infer<SchemaType>>) => React.ReactNode);
  className?: string;
  dependencies?: Dependency<Infer<SchemaType>>[];
}) {
  const objectFormSchema = getObjectFormSchema(formSchema);
  type FormType = Infer<typeof objectFormSchema>;

  const defaultValues: DefaultValues<FormType> | null = getDefaultValues(
    objectFormSchema,
    fieldConfig
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <fix later>
  const hookFormValidationErrors = useMemo(() => {
    // TODO: Stephen there's a bug here causing infinite re-renders
    // I can not see what the issue is I have tried all solutions in the docs
    // https://github.com/next-safe-action/adapter-react-hook-form
    return mapToHookFormErrors<typeof formSchema>(
      formActionProp.result?.validationErrors,
      { joinBy: "\n" }
    );
  }, []);

  const form = useForm<FormType>({
    resolver: zodResolver(objectFormSchema),
    defaultValues: defaultValues ?? undefined,
    values: valuesProp,
    errors: hookFormValidationErrors
  });

  async function onSubmit(values: Infer<SchemaType>) {
    // Reset the form action to clear the previous submission state
    formActionProp.reset();

    const parsedValues = formSchema.safeParse(values);
    if (parsedValues.success) {
      const result = await formActionProp.executeAsync(parsedValues.data);

      if (result?.validationErrors?._errors.length > 0) {
        form.setError("root.server", {
          message: result.validationErrors._errors?.[0],
          type: "server"
        });
      }
    }
  }

  return (
    <div className="w-full">
      <Form {...form}>
        <form
          onSubmit={event => {
            // biome-ignore lint/suspicious/noExplicitAny: <fix later>
            form.handleSubmit(onSubmit as any)(event);
            // When the onSubmit is async react-hook-form does not stop the event propagation
            event.stopPropagation();
          }}
          className={cn("space-y-5", className)}
        >
          <AutoFormObject
            schema={objectFormSchema}
            form={form}
            dependencies={dependencies}
            fieldConfig={fieldConfig}
          />
          {typeof children === "function"
            ? children(form.formState as FormState<Infer<SchemaType>>)
            : children}
          {form.formState.errors.root?.server && (
            <FormMessage>
              {form.formState.errors.root.server.message}
            </FormMessage>
          )}
        </form>
      </Form>
    </div>
  );
}

export default AutoForm;
